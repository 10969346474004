const PrivacyData = [
  {
    title: 'Information We Collect',
    content: `<span class="text-sm">We may collect information about you in a variety of ways. The information we may collect on the Site includes:</span>
        <ul class="list-decimal list-inside ml-4">
            <li class="py-1 text-sm text-black gap-1">Personal Data: Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards..</li>
            <li class="py-1 text-sm text-black gap-1"> Derivative Data: Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.</li>
        </ul>
        `,
  },
  {
    title: 'Use of Your Information',
    content: `<span class="text-sm">Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</span>
        <ul class="list-decimal list-inside ml-4">
            <li class="py-1 text-sm text-black gap-1">Create and manage your account.</li>
            <li class="py-1 text-sm text-black gap-1">Process your transactions and send you related information, including purchase confirmations and invoices.</li>
            <li class="py-1 text-sm text-black gap-1">Manage orders, provide customer support, and deliver the products and services you request.</li>
            <li class="py-1 text-sm text-black gap-1">Communicate with you, including responding to your comments, questions, and requests.</li>
            <li class="py-1 text-sm text-black gap-1">Improve and personalize our services.</li>
            <li class="py-1 text-sm text-black gap-1">Monitor and analyze trends, usage, and activities in connection with our services.</li>
        </ul>
        `,
  },
  {
    title: 'Security of Your Information',
    content: `<span class="text-sm">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</span>
        `,
  },
  {
    title: 'Policy for Children',
    content: `<span class="text-sm">We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.</span>
        `,
  },
  {
    title: 'Changes to This Privacy Policy',
    content: `<span class="text-sm">We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our Site and updating the effective date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.</span>`,
  },
  {
    title: 'Contact Us',
    content: `<span class="text-sm">If you have questions or comments about this Privacy Policy, please contact us at: <br/> PT Kin Sukses Utama
Fairgrounds SCBD Lot 14 Basement, Jalan Jendral Sudirman kav 52-53 Jakarta 12190</span>`,
  },
];

const TermsConditionData = [
  {
    title: 'Use of the Site',
    content: `<span class="text-sm">You must be at least 13 years old to use this Site. By using the Site, you warrant that you are at least 13 years of age and have the legal capacity to enter into this agreement.</span>`,
  },
  {
    title: 'Accounts',
    content: `<span class="text-sm">When you create an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.</span>`,
  },
  {
    title: 'Intellectual Property',
    content: `<span class="text-sm">All content on this Site, including text, graphics, logos, and images, is the property of Kin or its content suppliers and is protected by international copyright laws. Unauthorized use of any materials on this Site is strictly prohibited.</span>`,
  },
  {
    title: 'Termination',
    content: `<span class="text-sm">We may terminate or suspend your account and access to the Site at our sole discretion, without prior notice, for conduct that we believe violates these Terms or is harmful to other users of the Site, us, or third parties.</span>
        `,
  },
  {
    title: 'Limitation of Liability',
    content: `<span class="text-sm">Kin will not be liable for any damages that result from the use of, or the inability to use, the materials on this Site or the performance of the products, even if Kin has been advised of the possibility of such damages.</span>`,
  },
  {
    title: 'Governing Law',
    content: `<span class="text-sm">These Terms are governed by and construed in accordance with the laws of Indonesia, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Indonesia.
</span>`,
  },
  {
    title: 'Changes to These Terms',
    content: `<span class="text-sm">We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this Site. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.
</span>`,
  },
  {
    title: 'Contact Us',
    content: `<span class="text-sm">If you have questions or comments about this Privacy Policy, please contact us at: <br/> PT Kin Sukses Utama
Fairgrounds SCBD Lot 14 Basement, Jalan Jendral Sudirman kav 52-53 Jakarta 12190</span>`,
  },
];

export { PrivacyData, TermsConditionData };
