import FullLayout from 'layout/FullLayout';
import { PrivacyData } from '../DefaultData';

const PrivacyPolicy = () => {
  return (
    <FullLayout>
      <div className="relative gap-5 justify-center items-center 2xl:px-[180px] xl:px-[140px]">
        <div className="mx-auto xl:max-w-7xl lg:max-w-5xl py-6 h-full px-4">
          <h2 className="text-xl font-bold text-gray-900 leading-normal px-4">Privacy Policy</h2>
          <div className="mt-3 rounded-md  max-w-7xl mx-auto p-4">
            <span className="text-base text-gray-700">Effective Date: 1st July 2024</span>
            <p className="text-sm my-2">
              Welcome to Kin! This Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you visit our website{' '}
              <a className="text-primary-main" href="https://hellokinspace.com">
                https://hellokinspace.com/
              </a>{' '}
              (the "Site"). Please read this privacy policy carefully. If you do not agree with the
              terms of this privacy policy, please do not access the site.
            </p>
            <ul className="mt-2 space-y-8 text-gray-600">
              {PrivacyData.map((item, key) => {
                return (
                  <li className="flex gap-x-3" key={key}>
                    <span>
                      <strong className="text-base font-semibold text-gray-900">
                        {item.title}
                      </strong>{' '}
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: item.content }} />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default PrivacyPolicy;
