import FullLayout from 'layout/FullLayout';
import { useState } from 'react';
import ModalDialog from '../Components/ModalDialog';

const Learn = () => {
  const [showImage, setShowImage] = useState(false);
  const [imageShow, setImageShow] = useState('');
  const datas = [
    { id: 0, image: 'kin/assest/class/special.jpeg', alt: 'kin-1' },
    { id: 1, image: 'kin/assest/class/regular.jpeg', alt: 'kin-2' },
    { id: 2, image: 'kin/assest/class/mon.jpeg', alt: 'kin-3' },
    { id: 3, image: 'kin/assest/class/tue.jpeg', alt: 'kin-4' },
    { id: 4, image: 'kin/assest/class/wed.jpeg', alt: 'kin-5' },
    { id: 4, image: 'kin/assest/class/thu.jpeg', alt: 'kin-6' },
    { id: 4, image: 'kin/assest/class/fri.jpeg', alt: 'kin-7' },
    { id: 4, image: 'kin/assest/class/sat.jpeg', alt: 'kin-8' },
    { id: 4, image: 'kin/assest/class/sun.jpeg', alt: 'kin-9' },
  ];

  const openModal = (paramsImage) => {
    setShowImage(!showImage);
    setImageShow(paramsImage);
  };

  return (
    <FullLayout>
      <div className="2xl:px-[180px] xl:px-[140px]">
        <div className="mx-auto xl:max-w-7xl lg:max-w-5xl py-6 h-full px-4">
          <h2 className="text-xl font-bold text-gray-900 leading-normal px-4">Class</h2>
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-3">
            {datas.map((items, idx) => {
              return (
                <div
                  key={idx}
                  className="w-full cursor-pointer"
                  onClick={() => openModal(items.image)}>
                  <img src={items.image} className="rounded-2xl object-cover" alt={items.alt} />
                </div>
              );
            })}
            <ModalDialog open={showImage} setOpen={setShowImage} image={imageShow} />
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default Learn;
