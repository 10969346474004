import { FooterNavigation } from './DataMenu';

export default function FooterPage() {
  return (
    <footer>
      <div className="flex flex-col justify-between md:pt-4 pt-20 pb-2.5 md:px-8 px-6 text-kin-text">
        <div className="flex md:flex-row flex-col justify-between gap-3">
          <div className="flex md:flex-row flex-col gap-3">
            <div className="flex flex-col gap-1 max-w-[400px]">
              <h3 className="text-sm font-basicsemibold leading-6 text-kin-text">Location</h3>
              <ul className="space-y-4">
                <li className="text-sm font-basiclight leading-6 text-kin-text">
                  Fairgrounds SCBD Lot 14 Basement, Jalan Jendral Sudirman kav 52-53 Jakarta 12190,
                  Desa/Kelurahan Senayan, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi
                  DKI Jakarta, Kode Pos: 12190
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-basicsemibold leading-6 text-kin-text">Legal</h3>
              <ul className="space-y-1">
                <li>
                  <a
                    href="/privacy-policy"
                    className="text-sm font-basiclight leading-6 text-kin-text">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-condition"
                    className="text-sm font-basiclight leading-6 text-kin-text">
                    Terms Condition
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-basicsemibold leading-6 text-kin-text">Social</h3>
            <div className="flex md:justify-center justify-start space-x-6 md:order-2">
              {FooterNavigation.social.map((item, idx) => (
                <a href={item.href} key={idx}>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center mt-6 bg-primary-main">
        <div className="">
          <p className="text-left text-xs leading-5 text-white">
            &copy; 2024 PT Kin Sukses Utama, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
