import FullLayout from 'layout/FullLayout';
import { useState } from 'react';
import ModalDialog from '../Components/ModalDialog';

const Play = () => {
  const [showImage, setShowImage] = useState(false);
  const [imageShow, setImageShow] = useState('');
  const datas = [
    { id: 0, image: 'kin/assest/weekday-pass.jpg', alt: 'kin-1' },
    // { id: 1, image: 'kin/assest/weekday-reg.jpg', alt: 'kin-2' },
    { id: 2, image: 'kin/assest/weekend-pass.jpg', alt: 'kin-3' },
    // { id: 3, image: 'kin/assest/weekend-reg.jpg', alt: 'kin-4' },
  ];

  const openModal = (paramsImage) => {
    setShowImage(!showImage);
    setImageShow(paramsImage);
  };

  return (
    <FullLayout>
      <div className="2xl:px-[180px] xl:px-[140px]">
        <div className="mx-auto lg:max-w-4xl py-6 h-full px-4">
          <h2 className="text-xl font-bold text-gray-900 leading-normal px-4">Price List</h2>
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-1">
            {datas.map((items, idx) => {
              return (
                <div
                  key={idx}
                  className="w-full cursor-pointer"
                  onClick={() => openModal(items.image)}>
                  <img src={items.image} className=" rounded-2xl object-cover" alt={items.alt} />
                </div>
              );
            })}
            <ModalDialog open={showImage} setOpen={setShowImage} image={imageShow} />
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default Play;
