import { Dialog, DialogPanel, DialogBackdrop } from '@headlessui/react'

export default function ModalDialog({ open, setOpen, image }) {

    return (
        <Dialog open={open} onClose={() => setOpen(!open)} className="relative z-10 !block">
            <DialogBackdrop
                onClick={() => setOpen(!open)}
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden lg:max-w-3xl md:max-w-2xl max-w-md rounded-2xl bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8"
                    >
                        <div>
                            <img src={image} className="rounded-t-2xl object-cover" alt='img-modal' />
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => setOpen(!open)}
                                className="inline-flex w-full justify-center bg-white px-3 py-4 text-sm font-semibold text-primary-main shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Close
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
