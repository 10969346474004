import { IconBrandWhatsapp, IconBrandInstagram, IconMap2 } from '@tabler/icons-react';
const LinkPageDataLeft = [
  //   { name: 'Shop', href: '/' },
  { name: 'Play', href: '/play' },
  { name: 'Learn', href: '/learn' },
];

const LinkPageDataRight = [
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contact' },
];

const LinkPageDataFull = [
  // { name: "Shop", href: "/" },
  { name: 'Play', href: '/play' },
  { name: 'Learn', href: '/learn' },
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contact' },
];

const FooterNavigation = {
  main: LinkPageDataLeft,
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/kinspace.id',
      icon: <IconBrandInstagram className="w-[26px] h-[26px]" />,
      icon_mobile: <IconBrandInstagram className="w-8 h-8" />,
    },
    {
      name: 'Email',
      href: 'https://maps.app.goo.gl/xWX7JgpFhTuuu7jLA',
      icon: <IconMap2 className="w-6 h-6" />,
      icon_mobile: <IconMap2 className="w-8 h-8" />,
    },
    {
      name: 'Whatsapp',
      href: 'https://api.whatsapp.com/send?phone=6287776886880&text=Hello Kin Space,I would like to ask you a few questions about your place?',
      icon: <IconBrandWhatsapp className="w-6 h-6" />,
      icon_mobile: <IconBrandWhatsapp className="w-8 h-8" />,
    },
  ],
};

export { LinkPageDataLeft, LinkPageDataRight, LinkPageDataFull, FooterNavigation };
