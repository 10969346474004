import React, { useState, useEffect } from 'react';
import HeaderPage from './HeaderPage';
import FooterPage from './FooterPage';

const FullLayout = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="flex flex-col h-screen justify-between">
        <div
          className={`fixed top-0 left-0 w-full duration-[600ms] z-[15] ${
            isSticky ? 'shadow-sm' : ''
          }`}>
          <HeaderPage />
        </div>
        <div className="flex-1 pt-[60px]">{children}</div>
        <FooterPage />
      </div>
    </div>
  );
};

export default FullLayout;
