import FullLayout from 'layout/FullLayout';
import { TermsConditionData } from '../DefaultData';

const TermsCondition = () => {
  return (
    <FullLayout>
      <div className="2xl:px-[180px] xl:px-[140px]">
        <div className="mx-auto xl:max-w-7xl lg:max-w-5xl py-6 h-full px-4">
          <h2 className="text-xl font-bold text-gray-900 leading-normal px-4">Terms Condition</h2>
          <div className="mt-3 rounded-md max-w-7xl mx-auto p-4">
            <span className="text-base text-gray-700">Effective Date: 1st July 2024</span>
            <p className="text-sm my-2">
              Welcome to Kin ("we", "our", "us"). By using our website{' '}
              <a className="text-primary-main" href="https://hellokinspace.com">
                https://hellokinspace.com/
              </a>{' '}
              (the "Site"), you agree to comply with and be bound by the following terms and
              conditions ("Terms"). Please review them carefully. If you do not agree to these
              Terms, you should not use this Site.
            </p>
            <ul className="mt-2 space-y-8 text-gray-600">
              {TermsConditionData.map((item, key) => {
                return (
                  <li className="flex gap-x-3" key={key}>
                    <span>
                      <strong className="text-base font-semibold text-gray-900">
                        {item.title}
                      </strong>{' '}
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: item.content }} />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default TermsCondition;
